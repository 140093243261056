import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { ensureUser, getPageURL, stringFromLength } from '../../util/data';
import { FormattedMessage } from '../../util/reactIntl';

import SectionMapMaybe from './SectionMapMaybe';
import SectionReviews from './SectionReviews';
import SectionTextMaybe from './SectionTextMaybe';

import { Button, H4, IconProfileCard, Modal, NamedLink, ResponsiveImage, SocialShare } from '../../components';

import css from './ListingPage.module.css';

const SectionCompany = props => {
  const {
    intl,
    config,
    reviews,
    richTitle,
    currentUser,
    currentListing,
    fetchReviewsError,
    isAuthenticated,
    authorListings,
    onUpdateProfile,
    onManageDisableScrolling,
    isOwnListing,
    currentUserListings
  } = props;
const showInterestedButton =( currentUserListings && currentUserListings.length && currentUserListings[0] && currentUserListings[0].attributes && currentUserListings[0].attributes.state && currentUserListings[0].attributes.state == 'published') || false ; 

  const [showMore, setShowMore] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {
    // description = '',
    geolocation = null,
    publicData = {},
  } = (currentListing.id && currentListing.attributes) || {};
  const {
    // location: listingLocation,
    address,
    timezone,
  } = publicData;

  // const { address = '' } = listingLocation || {};
  const currentAuthor = (currentListing && currentListing.id && currentListing.author) || {};
  const ensuredAuthor = ensureUser(currentAuthor);
  const { bio, publicData: aPublicData } = (ensuredAuthor && ensuredAuthor.id && ensuredAuthor.attributes.profile) || {};
  const { companyFollowing = [], positionInterested = [] } = (currentUser && currentUser.id && currentUser.attributes.profile.publicData) || {};
  const isCompanyFollowed = ensuredAuthor && ensuredAuthor.id && companyFollowing && companyFollowing.length && companyFollowing.includes(ensuredAuthor.id.uuid);
  const isPositionInterested = ensuredAuthor && ensuredAuthor.id && showModal && showModal.id && positionInterested && positionInterested.length && positionInterested.includes(showModal.id.uuid);
  const {
    companyName,
    foundingYear,
    sizeOfCompany,
    companyIndustry,
  } = aPublicData || {};

  return (
    <div className={classNames(css.contentWrapperForProductLayout, css.companyLayout)}>
      <div className={classNames(css.listingGrid, css.companyListingGrid)}>
        <div className={css.leftGird}>
          {currentUser && currentUser.id && isAuthenticated
            ? <div className={css.rotateHeading}>
              <h1> Hello, {currentUser.attributes.profile.firstName}</h1>
            </div>
            : null}
          <div className={css.companySocialLinksContent}>
            <div className={css.socialLinks}>
              <SocialShare
                isPlain
                url={getPageURL()}
                PageName={'ListingCompanyPage'}
                onClose={() => { }}
                isAuthenticated={isAuthenticated}
                onManageDisableScrolling={onManageDisableScrolling}
              />
              <div
                className={css.companyFollowBtn}
                onClick={() => onUpdateProfile({ follwerId: ensuredAuthor && ensuredAuthor.id && ensuredAuthor.id.uuid })}
              >
                {isCompanyFollowed ? 'Followed' : 'Follow Company'}
              </div>
            </div>
            {ensuredAuthor.profileImage && ensuredAuthor.profileImage.id
              ? <div className={classNames(css.listingProfileImageBox, !isAuthenticated && css.disableOverlay)}>
                <ResponsiveImage
                  alt='display image'
                  image={ensuredAuthor.profileImage}
                  variants={['default']}
                />
              </div>
              : null}
          </div>

          <div className={css.companyName}>
            {/* <h6>Meet,</h6> */}
            {companyName
              ? <h4> <FormattedMessage id="ListingPage.orderTitle" values={{ title: companyName }} /></h4>
              : null}
          </div>

          {bio
            ? <div className={css.aboutUs}>
              <h5>
                About Us
              </h5>
              {typeof bio == 'string' ? showMore == ('bio') || bio.length <= 287 ? bio : stringFromLength(bio, 284, true) : null}
              {bio && bio.length > 284
                ? <a
                  className={css.showmore}
                  onClick={() => setShowMore(showMore == ('bio') ? false : 'bio')}
                >
                  {showMore == ('bio') ? ' READ LESS' : ' READ MORE'}
                </a>
                : null}
            </div>
            : null}

          <div className={css.mapBox}>
            {address && geolocation
              ? <div className={css.mapLocation} id={'mapbox'} >
                <SectionMapMaybe
                  listingId={currentListing.id}
                  geolocation={geolocation}
                  publicData={publicData}
                  mapsConfig={config.maps}
                />
              </div>
              : null}
            <div className={css.locationList}>
              {address
                ? <div className={css.locationName}>
                  <IconProfileCard type="location" />
                  <span>{address}</span>
                </div>
                : null}
              <div className={css.locationName}>
                <IconProfileCard type="time" />
                <span>Timezone: {moment().tz(timezone ? timezone : moment.tz.guess()).format('z')}</span>
              </div>
            </div>
            <div className={css.moreCompanyInfo}>
              {sizeOfCompany ? <div>
                <b>Company Size:</b>
                <span>{sizeOfCompany}</span>
              </div> : null}

              {foundingYear ? <div>
                <b>Founded in:</b>
                <span>{foundingYear}</span>
              </div> : null}
              {companyIndustry && companyIndustry.length ? <div>
                <b>Company Industry:</b>
                <span> {companyIndustry.map((val, i) => {
                  return val + `${(companyIndustry.length == i + 1) ? '' : ', '}`
                })}</span>
              </div> : null}
              {/* <div>
                <b>Company Headquarters:</b>
                <span>Torontosss</span>
              </div> */}
            </div>
          </div>
          {reviews && reviews.length
            ? <SectionReviews
              reviews={reviews}
              fetchReviewsError={fetchReviewsError}
            />
            : null}

        </div>
        <div className={classNames(css.rightGrid, css.companyRightGrid)}>
          <h5>Available Positions</h5>

          {authorListings && authorListings.length
            ? authorListings.map((authorListing, i) => {
              const { title, description, price, publicData } = (authorListing && authorListing.id && authorListing.attributes) || {};
              const { roles = [], start, workType } = publicData || {};

              return (<div className={css.positions} key={title + '-' + i}>
                <div className={css.cardTop}>
                  {Array.isArray(currentListing.images)
                    ? <div className={classNames(css.positionsImage)}>
                      <ResponsiveImage
                        alt='display image'
                        image={ensuredAuthor.profileImage}
                        variants={['default']}
                      />
                    </div>
                    : null}
                  <div>
                    <h4>{title}</h4>
                    <ul>
                      {(roles && roles.length && roles[0] && roles[0].perHourRate && roles[0].perHourRate.label) ?
                        <p className={css.hourlyRate}>{roles[0]?.perHourRate?.label.replace(/^CA\$/, '')}/Hourly</p>
                        : <p className={css.hourlyRate}>${price.amount / 100}/Hourly</p>}
                      {(roles && roles.length && roles[0] && roles[0].perHourRate && roles[0].perHourRate.label)
                        ? <li>
                          {roles[0].availableHoursPerWeek.label} Weekly
                        </li>
                        : null}
                      {roles && roles.length ? <li>Start {roles[0].start}</li> : null}
                      {workType ? <li>{workType == 'flexible' ? "Flexible to anything" : workType.charAt(0).toUpperCase() + workType.slice(1)}</li> : null}

                    </ul>
                  </div>
                </div>
                <div className={css.cardBottom}>
                  <p>{description && description.length > 122 ? stringFromLength(description, 119, true) : description}
                    ...<span className={css.seeDetailsBtn} onClick={() => setShowModal(authorListing)}>
                      See Details
                    </span>
                  </p>
                </div>
              </div>)
            })
            : null}
        </div>
      </div>

      <Modal
        id="ListingPage.SeAllModal"
        isOpen={!!showModal}
        onClose={() => setShowModal(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        className={css.jobmodalDetails}
        type={'MultiPurposeModal'}
      >
        {showModal && showModal.id
          ? <div className={css.jobModalContent}>
            <h3 className={css.modalTitle}>{showModal?.attributes?.title}
            </h3>
            <p className={css.companyName}>{companyName}</p>
            <ul className={css.jobRequirements}>
              <li>
                <span></span>
                <span>{showModal.attributes.publicData.workType == 'flexible' ? "Flexible to anything" : showModal?.attributes?.publicData?.workType?.charAt(0)?.toUpperCase() + showModal?.attributes?.publicData?.workType?.slice(1)} | {showModal?.attributes?.publicData?.roles[0]?.availableHoursPerWeek?.label} Weekly</span>
              </li>
            </ul>

            <h5 className={css.modalSectionTitle}>The Role</h5>
            <p className={css.jobDescription}>{showModal?.attributes?.description}</p>
            <h5 className={css.modalSectionTitle}>Other Must Haves</h5>
            <ul className={css.jobRequirements}>
              {showModal?.attributes?.publicData?.responsibilities?.map(responsibility => (
                <li>
                  <span><IconProfileCard type="bolder_tick" /> </span>
                  <span>{responsibility}</span>
                </li>
              ))}
            </ul>
            <h5 className={css.modalSectionTitle}>Related Industries </h5>

            <ul className={css.companiesList}>
              {showModal.attributes.publicData.roles.map(role => (
                <li>
                  {role.label}
                </li>))}
            </ul>
            <h5 className={css.modalSectionTitle}>Why join this company?</h5>
            <p>{showModal.attributes.publicData.whyJoin}</p>
            {/* <ul className={css.companiesList}>
              <li>
                {showModal.attributes.publicData.whyJoin}
              </li>
              <li>
                Dummy data Marketplaces
              </li>
            </ul> */}

            {isOwnListing ? <Button
              className={css.buttonInterested}><NamedLink name="AlgoliaSearchPage" className={css.buttonInterested}>
                Discover Talent

              </NamedLink></Button> : <Button
                className={css.buttonInterested}
                onClick={() => {onUpdateProfile({ positionId: showModal.id.uuid })}}
                disabled={!showInterestedButton}
              >
              {isPositionInterested ? 'Already Interested' : 'I’m Interested'}
            </Button>
            }
          </div>
          : null}
      </Modal>
    </div>
  );
};

export default SectionCompany;